<template>
    <div class="search-box">
       
        <BFormInput list="users-list" 
        v-model="searchedItem" 
        type="search" 
        :placeholder="placeHolder" 
        class="form-control rounded-pill"/>
        
    </div>
  </template>
  <script>
  
  
  export default{
      data(){
          return{
              searchedItem: null,
          }
      },
      props:{
        placeHolder:{
          type: String,
          default: "Search here"
        },
        defaultValue:{
          type: String,
          default: null,
        }
      },
      mounted() {
          this.debouncedLoadData = this.debounce(this.emitSearchedItem, 1000);
        },
      watch: {
          searchedItem(newValue) {
               this.debouncedLoadData(newValue);
      },
      defaultValue(){
        this.getDefaultValue();
      }
      },
      methods: {
    debounce(func, delay) {
      let timer;
      return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
      };
    },
     emitSearchedItem(searchValue) {
          this.$emit('getSearched', searchValue);
      },
      getDefaultValue(){
        this.searchedItem=this.defaultValue;
      }
  
  }
  }
  
  
  
  </script>
  <style scoped>
  </style>
  