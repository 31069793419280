<template>
  <Layout>
   

    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">
          
          <div class="card-body table mb-0">
            <div class="main_header_ctas d-flex flex-column flex-md-row gap-2 flex-wrap align-items-center justify-content-between py-2">
              <div class="d-flex align-items-center flex-column flex-md-row"> 
                <PageHeader :title="title" :items="items" class="poppins fw-normal pt-3 ps-3" />
                <div class=""> 
                <searchInput @getSearched="handleSearch"/>
              </div>
              </div>
              
              <!-- <div class="del_btn-wrapper">

                <button v-if="multipuleIds.length >= 1" @click="deleteBulk" class="
    btn
    border-0
    px-5
    fw-bold
    text-white
    btn btn-primary
    waves-effect waves-light
    w-md
    primary-button
    rounded-pill
  ">
                  Delete All
                </button>
              </div> -->
             
              <div class="add_new border-orange rounded-pill p1 d-flex" > <router-link to="/users/add" class="
             border-0
             px-5
             fw-bold
             text-white
             waves-effect waves-light
             w-md
             primary-button
             rounded-pill
           ">
                  Add New
                </router-link></div>
            </div>

            <!-- <div class="table-responsive">
              <table id="datatable" class="table table-centered table-nowrap mb-0 ">
                <thead class="table-light bg-transparent">
                  <tr>
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input @change="selectAllRecord" v-model="selectAll" type="checkbox" class="form-check-input"
                          id="customCheck1" />
                        <label class="form-check-label" for="customCheck1">&nbsp;</label>
                      </div>
                    </th>
                    <th>User Name</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                    <th>Add Business</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr colspan="5" v-if="loading">
                    <td colspan="6">
                      <APILoader :loading="loading" class="align-middle text-center"></APILoader>
                    </td>
                  </tr>
                  <tr v-else v-for="(user, index) in users" v-bind:key="index">
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input type="checkbox" class="form-check-input" v-model="multipuleIds" :value="user.id"
                          id="customCheck1" />
                        <label class="form-check-label" for="customCheck1">&nbsp;</label>
                      </div>
                    </th>
                    <td>
                      <div class="userDetail">
                        <div class="d-flex align-items-center">
                          <img v-if="!user.avatar" src="../../../../../public/avatar.jpg" class="user-img me-2"
                            alt="..." width="30px" height="30px" />
                          <img v-else :src="user.avatar" class="user-img me-2" alt="..." width="30px" height="30px">
                          <span>{{ user.full_name }}</span>
                        </div>
                      </div>
                    </td>
                    <td>{{ user.mobile }}</td>
                    <td>{{ user.email }}</td>
                    <td>
                      <a href="/business/add" class="primary-color">Add Business</a>
                    </td>
                    <td class="table-icon">
                      <div class="d-flex">
                        <button style="margin: 10px" @click="userUpdate(user.id)" class="p-0 bg-transparent border-0">
                          <span class="text-primary">
                            <i class="uil-edit"></i>
                          </span>
                        </button>
                        <button @click="deleteUser(user.id)" class="p-0 bg-transparent border-0">
                          <span class="text-danger">
                            <i class="uil-trash-alt"></i>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          <GeneralTable :isProcessing="processing" 
            :items="items"  :fields="fields" 
            @deleteItem="deleteUser"
            @editItem="userUpdate">
          </GeneralTable>
          <Pagination
          @paginator="allUsers"
          :pagination="paginatelinks"
          :showing="paginate"
        />
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";
import GeneralTable from "../../../../components/MagicTable/GeneralTable.vue";
import Pagination from "../../../../components/general/pagination.vue"
import searchInput from "../../../../components/general/searchInput.vue"

export default {
  page: {
    title: "User Listing",
    meta: [
      {
        name: "add-user",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    GeneralTable,
    Pagination,
    searchInput,
  },

  data() {
    return {
      fields: [
        { key: 'select', label: 'Select', sortable: false },  
        { key: 'id'},
        { key: 'avatar_with_title', label: 'User Name'},
        { key: 'email',tdClass:'align-middle'},
        { key: 'mobile', label: 'Phone',tdClass:'align-middle'},
        { key: 't_shirt_size',tdClass:'align-middle'},
        { key: 'registeredon', label: 'Registered On',tdClass:'align-middle'},
        { key: 'action',tdClass:'align-middle'},
      ],
      selectedRows: [], 
      items: [],
      currentPage: 1,
      itemsPerPage: 10,
      multipuleIds: [],
      selectAll: false,
      loading: true,
      users: null,
      paginatelinks: [],
      title: "User Listing",
      processing: false,
      // items: [
      //   {
      //     text: "Omaha",
      //   },
      //   {
      //     text: "User Listing",
      //     active: true,
      //   },
      // ],
    };
  },
  // mounted function
  mounted() {
    this.allUsers({});
    this.processing = true;
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.items.slice(start, start + this.itemsPerPage);
    }
  }, 
  methods: {
    deleteUser(id) {
      let apiUrl = `delete-user/`;
           let confirmationMessage = "User deleted successfully."
           this.processing = true;
           this.deleteIt(apiUrl, id,confirmationMessage)
           .then(() => {
            this.processing = false;
            }).catch(error => {
                console.error('Error deleting job:', error);
                this.processing = false;
            });
      console.log('Received ID from child:', id);
    },
    handleSearch(searchItem) {
       this.allUsers({searchItem});
     // this.allUsers({ url: `search-users?query=${searchTerm}` }); 
  },
    updateCurrentPage(newPage) {
      this.currentPage = newPage; 
    },
    randerData(response) { 
      this.paginate = response.data.data.links;
      this.paginate={
              first_page: response.data.data.first_page_url,
              last_page: response.data.data.last_page_url,
              links: response.data.data.links,
              from: response.data.data.from,
              to: response.data.data.to,
              total: response.data.data.total,
            };
     
            this.paginatelinks=this.paginate.links.map(item=>({
              url: item.url,
              label: item.label,
              active: item.active,
            }));
      console.log(this.paginatelinks, "----")
      let users = Array.isArray(response.data.data.data) && response.data.data.data.length > 0 
  ? response.data.data.data 
  : [];   
      this.items = users.map(user => ({
      id: user.id,
      avatar:user.avatar,
      name: user.full_name,
      email: user.email,
      mobile: user.mobile,
      t_shirt_size: user.t_shirt_size,
      registeredon: user.date_register
    }));
    this.processing = false;
    console.log(response, "---- response");
    console.log(this.items, "---- mapped items")
 
    },

    allUsers({url= null, searchItem=null}) {
      this.processing = true;
      let endPoint = "all-users";
      endPoint = url ? url : endPoint;
      this.$axios
        .get(endPoint, {params:{search: searchItem}})
        .then((response) => {
          console.log('User Data:', response.data);
          setTimeout(() => this.randerData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
           this.processing = false;
        });
    },
    getRecords(link) {
      console.log(link);
    },
    // Delete user record
    async delete(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.delete("delete-user/" + id);
        this.responseDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    // Alert Swal while deleting the record
    // deleteUser(id) {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes, delete it!",
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       await this.delete(id);
    //       let status = this.responseDelete;
    //       if (status == true) {
    //         Swal.fire("Deleted!", "Record has been deleted.", "success");
    //         this.allUsers({});
    //       }
    //     }
    //   });
    // },
    updateRecords(records) {
      this.users = records;
      this.links = records;
    },
    // Record update Function
    userUpdate: function (id) {
      this.$router.push({
        name: "update-user",
        params: { id: id },
      });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.users.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("delete-user-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allUsers({});
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>